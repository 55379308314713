import React from "react";
import ResponsiveContainer from "../../components/layout/ResponnsiveContainer";
import {Grid, Header, Image, Segment} from "semantic-ui-react";

class AboutPage extends React.Component {
    componentDidMount = async ()  => {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <ResponsiveContainer>
                <Segment style={{padding: '8em 0em'}} vertical>
                    <Grid container stackable verticalAlign='middle'>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as='h3' style={{fontSize: '2em'}}>
                                    ¿Quiénes somos?
                                </Header>
                                <p style={{fontSize: '1.33em'}}>
                                    Somos un programa radial donde el menú son los vinos y los vinilos. El programa es presentado por
                                    Germán Chávez, Luis Ayala, Alberto Clary, Wilfredo Sandoval y Alex Blanco de
                                    <a  target="_blank" rel="noopener noreferrer" href="https://musicartsandtastings.com/"> Music, Arts & Tastings</a>.
                                    Cada programa sacamos lo mejor de nuestro repertorio de vinilos y le hacemos el maridaje perfecto con vino.
                                    <br/>
                                    <br/>
                                    Agradecemos también la colaboración del brujo de <a target="_blank" rel="noopener noreferrer" className="item" href="https://batch13wines.com/">Batch 13</a>. Asegúrate de preguntar por él cuando los visites su tienda de vinos,
                                    cervezas y licores ubicada en <a target="_blank" rel="noopener noreferrer" className="item" href="https://goo.gl/maps/uqsAwew7ZS3oEmrn7">1724 14th St NW</a>.
                                </p>
                            </Grid.Column>
                            <Grid.Column floated='right' width={6}>
                                <Image bordered rounded size='large' src='/images/about.jpg' alt='Pila de Discos de Vinilo'/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </ResponsiveContainer>
        );
    }
}
export default AboutPage;
