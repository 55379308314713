import React from "react";
import ResponsiveContainer from "../../components/layout/ResponnsiveContainer";
import { Grid, Header, Image, Segment} from "semantic-ui-react";

class ContactPage extends React.Component {
    componentDidMount = async ()  => {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <ResponsiveContainer>
                <Segment style={{padding: '8em 0em'}} vertical>
                    <Grid container stackable verticalAlign='middle'>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as='h3' style={{fontSize: '2em'}}>
                                    Contáctanos
                                </Header>
                                <p style={{fontSize: '1.33em'}}>
                                    ¿Tienes alguna duda? Envíanos un correo a <a target="_blank" rel="noopener noreferrer" className="item" href="mailto:info@vinosyvinilostheshow.com">info@vinosyvinilostheshow.com
                                </a> y te responderemos a la brevedad posible.
                                </p>
                                <br/>
                                <br/>
                            </Grid.Column>
                            <Grid.Column floated='right' width={6}>
                                <Image bordered rounded size='large' src='/images/contact.jpg' alt='Persona buscando discos'/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </ResponsiveContainer>
        );
    }
}
export default ContactPage;
