import React from 'react'
import { Router,Route,Switch} from "react-router-dom";
import HomePage from "../../pages/HomePage";
import AboutPage  from "../../pages/AboutPage";
import ClubPage from "../../pages/ClubPage";
import ContactPage from "../../pages/ContactPage";
import PodcastPage from "../../pages/PodcastPage";
import LivePage from "../../pages/LivePage";
import NotFoundPage from "../../pages/NotFoundPage";
import ReactGA from 'react-ga';
import history from '../../utils/history';
import '../../css/main.css';

ReactGA.initialize('UA-12012271-3');

history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
});

class App extends React.Component {
    renderApp = () =>{
        return (
            <Router history={history}>
                <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/en-vivo" exact component={LivePage} />
                <Route path="/podcasts" exact component={PodcastPage} />
                <Route path="/club" exact component={ClubPage} />
                <Route path="/quienes-somos" exact component={AboutPage} />
                <Route path="/contactanos" exact component={ContactPage} />
                <Route path="*" component={NotFoundPage} />
                </Switch>
            </Router>
        );
    };
    render(){
        return (
            <div>
                {this.renderApp()}
            </div>
        );
    }
}

export default App;
