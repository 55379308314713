import React from "react";
import ResponsiveContainer from "../../components/layout/ResponnsiveContainer";
import { Grid, Header, Image, Segment} from "semantic-ui-react";

class PodcastPage extends React.Component {
    componentDidMount = async ()  => {
        window.scrollTo(0, 0);
    }
    renderList1 = () =>{
        let navigation = [
            {display: "Spotify", href: "https://open.spotify.com/show/3qqJhCKQhhw2Rg5gS2HYE5?si=OAQ2UxRISi-9oIX60XdJ8Q"},
            {display: "Google Podcasts", href: "https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuc3ByZWFrZXIuY29tL3Nob3cvMjcxOTMwMy9lcGlzb2Rlcy9mZWVk"},
            {display: "Apple Podcasts", href: "https://podcasts.apple.com/us/podcast/vinos-vinilos-the-show/id1504521362?uo=4"},
            {display: "Spreaker", href: "https://www.spreaker.com/show/the-latin-rock-invasion-1"}];
        return navigation.map((nav,index) => {
            return (this.renderLink(nav, index));
        });
    };
    renderList2 = () =>{
        let navigation = [
            {display: "iHeartRadio", href:"https://www.iheart.com/podcast/966-vinos-vinilos-the-show-61743370"},
            {display: "Youtube", href: "https://www.youtube.com/user/latinrockinvasiondc"},
            {display: "Soundcloud", href: "https://soundcloud.com/vinos-y-vinilos"},
            {display: "Deezer", href: "https://www.deezer.com/en/show/1032502"}];
        return navigation.map((nav,index) => {
            return (this.renderLink(nav, index));
        });
    };
    renderLink = (nav, index) =>{
        return (
            <a target="_blank" key={index} rel="noopener noreferrer" className="item" href={nav.href}>{nav.display}</a>
        );
    }
    render() {
        const spotifyEmbeded =
            <iframe src="https://open.spotify.com/embed-podcast/show/3qqJhCKQhhw2Rg5gS2HYE5" width="100%" height="232"
                    title="Vinos y Vinilos en Spotify"
                    frameBorder="0" allow="encrypted-media"></iframe>;
        return (
            <ResponsiveContainer>
                <Segment style={{padding: '8em 0em'}} vertical>
                    <Grid container stackable verticalAlign='top'>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as='h3' style={{fontSize: '2em'}}>
                                    Vinos y Vinilos The Show Podcast
                                </Header>
                                <p style={{fontSize: '1.33em'}}>
                                    Si no pudiste escucharnos en vivo, no te preocupes. Nos puedes encontrar en la plataforma de podcast de tu preferencia.
                                </p>
                                <Grid container  verticalAlign='middle'>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                <div className="ui container" >
                                    <div className="ui link list">
                                    {this.renderList1()}
                                    </div>
                                </div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <div className="ui container" >
                                                <div className="ui link list">
                                                    {this.renderList2()}
                                                </div>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <br/>
                                <br/>
                                {spotifyEmbeded}

                            </Grid.Column>
                            <Grid.Column floated='right' width={6}>
                                <Image bordered rounded size='large' src='/images/podcast.jpg' alt='Logo de Vinos y Vinilos con podcasts'/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </ResponsiveContainer>
        );
    }
}
export default PodcastPage;
