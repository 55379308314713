import {Link, withRouter} from "react-router-dom";
import React  from 'react';

class MenuItems extends React.Component {
    render = () => {
        return (
            <>
                <Link className="item" to="/">
                    <img className="ui mini image" src="/images/logo.png" alt="Vinos y Vinilos Logo"/>
                </Link>
                {this.renderList()}
            </>
        );
    }
    renderList = () =>{
        let navigation = [
            {display: "Home", href: "/"},
            {display: "En Vivo", href: "/en-vivo"},
            {display: "Podcasts", href: "/podcasts"},
            {display: "The Club", href: "/club"},
            {display: "Quiénes somos", href: "/quienes-somos"},
            {display: "Contáctanos", href: "/contactanos"}];
        return navigation.map((nav,index) => {
            return (this.renderLink(nav, index));
        });
    };
    renderLink = (nav,index) =>{
        const className = `item ${this.props.location.pathname === nav.href?'active':''}`;
        //const className = "item"
        return <Link key={index} className={className} to={nav.href} >{nav.display}</Link>;
    };
}
export default withRouter(MenuItems);
