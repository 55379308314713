import React from "react";
import {Container, Grid, Header, List, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
const renderList = () =>{
    let navigation = [
        {display: "En Vivo", href: "/en-vivo"},
        {display: "Podcasts", href: "/podcasts"},
        {display: "The Club", href: "/club"},
        {display: "Quiénes somos", href: "/quienes-somos"},
        {display: "Contáctanos", href: "/contactanos"}];
    return navigation.map((nav,index) => {
        return (<Link key={index} className="item" to={nav.href} >{nav.display}</Link>);
    });
};

export const renderFooter = () => {
    return (
        <Segment inverted vertical style={{padding: '5em 0em 0em 0em'}}>
            <Container>
                <Grid divided inverted stackable>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content='Nosotros'/>
                            <List link inverted>
                                {renderList()}
                            </List>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Header inverted as='h4' content='Nuestros compañeros'/>
                            <List link inverted>
                                <a target="_blank" rel="noopener noreferrer" className="item" href="https://tuvidafm.com/">TuVidaFM.com</a>
                                <a target="_blank" rel="noopener noreferrer" className="item" href="https://batch13wines.com/">Batch 13</a>
                                <a target="_blank" rel="noopener noreferrer" className="item" href="https://musicartsandtastings.com/">Music, Arts & Tastings </a>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Header as='h4' inverted>
                                Suíguenos en nuestras redes sociales
                            </Header>
                            {renderLogos()}

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <Segment inverted style={{padding: '1em 0em'}}>

            <Container textAlign="center">
            <div className="ui item">©2020 Vinos y Vinilos The Show. All Rights Reserved.</div>
            </Container >
            </Segment>
        </Segment>);
};

export const renderFooter2 = (content) => {
    const style = {
        padding: "6px",
        position: "fixed",
        left: "0",
        bottom: "0",
        height: "30px",
        width: "100%",
        borderRadius: "0",
        zIndex: "100",
    };

    const phantom = {
        display: 'block',
        padding: '20px',
        height: '30px',
        width: '100%',
    };
    return (
        <footer style={phantom} >
            <div className="ui inverted footer segment"  style={style}>
                {content}
            </div>
        </footer>
    );
};
export const renderLogos = () => {
    return (
        <div className="ui container" style={{padding: '0em 2em'}}>
            <div className="ui horizontal inverted massive link list">
                <a target="_blank" rel="noopener noreferrer" className="item" href="https://www.facebook.com/groups/2022814671134081/">
                    <i className="icon facebook"/></a>
                <a target="_blank" rel="noopener noreferrer" className="item" href="https://www.instagram.com/vinos_y_vinilos_the_show/">
                    <i className="icon instagram"/></a>
                <a target="_blank" rel="noopener noreferrer" className="item" href="https://twitter.com/rockactivodc/">
                    <i className="icon twitter" /></a>
            </div>
        </div>
    );
};
