import React from "react";
import ResponsiveContainer from "../../components/layout/ResponnsiveContainer";
import {Button, Grid, Header, Image, Segment} from "semantic-ui-react";

class ClubPage extends React.Component {
    componentDidMount = async ()  => {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <ResponsiveContainer>
                <Segment style={{padding: '8em 0em'}} vertical>
                    <Grid container stackable verticalAlign='middle'>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as='h3' style={{fontSize: '2em'}}>
                                    Vinos y Vinilos The Club
                                </Header>
                                <p style={{fontSize: '1.33em'}}>
                                    ¿Te gusta el vino? ¿Coleccionas vinilos? Te invitamos a ser parte de nuestro club en el
                                    cual con tu membresía mensual recibirás  un paquete de 4 vinilos y una botella de vino. Los discos son seleccionados basados en tus gustos musicales y las recomendaciones de vinos hechas por los expertos
                                    de la tienda <a target="_blank" rel="noopener noreferrer" className="item" href="https://batch13wines.com/">Batch 13</a> en Washington DC.
                                </p>
                            </Grid.Column>
                            <Grid.Column floated='right' width={6}>
                                <Image bordered rounded size='large' src='/images/club.jpg' alt='Grupo de discos organizados'/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <a target="_blank" rel="noopener noreferrer" className="item" href="http://paypal.me/rockactivodc/25">
                                <Button size='huge'>
                                    Únete hoy, por solo $25
                                </Button>
                                </a>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </ResponsiveContainer>
        );
    }
}
export default ClubPage;
