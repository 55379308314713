/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import ResponsiveContainer from "../../components/layout/ResponnsiveContainer";
import {
    Button, Container, Divider, Grid, Header,  Image, Segment,
} from 'semantic-ui-react';
import {WIDTH_LIMIT} from "../../utils/constants";
import {Link} from "react-router-dom";

const HomepageHeading = ({ mobile }) => (
    <Container text>
        <img style={{
            marginBottom: 0,
            marginTop: mobile ? '0.5em' : '4em',
        }}
             className="ui medium image centered" src="/images/logo.png" alt="Vinos y Vinilos Logo">
        </img>
        <Header
            as='h1'
            content='Vinos y Vinilos The Show'
            inverted
            style={{
                fontSize: mobile ? '2em' : '4em',
                fontWeight: 'normal',
                marginBottom: 0,
            }}
        />
        <Header
            as='h2'
            content='por TuVidaFM.com, ¡salud!'
            inverted
            style={{
                fontSize: mobile ? '1.5em' : '1.7em',
                fontWeight: 'normal',
                marginTop: mobile ? '0.5em' : '1.5em',
            }}
        />
    </Container>
)
class HomePage extends React.Component {
    componentDidMount = async ()  => {
        window.scrollTo(0, 0);
    }
    render() {
        const mobile = window.innerWidth<WIDTH_LIMIT;
        const minHeight = mobile?500:600;
        return (
            <ResponsiveContainer>
                <Segment
                    inverted
                    textAlign='center'
                    style={{ minHeight: minHeight, padding: '1em 0em' }}
                    vertical
                >
                    <HomepageHeading mobile={mobile} />
                </Segment>
                <Segment style={{padding: '6em 0em'}} vertical>
                    <Grid container stackable verticalAlign='middle'>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as='h3' style={{fontSize: '2em'}}>
                                    The Show
                                </Header>
                                <p style={{fontSize: '1.33em'}}>
                                    Somos el show que te trae durante la semana el mejor maridaje de vino y música en vinilo.  Sintonízanos en vivo por <a target="_blank" rel="noopener noreferrer" className="item" href="https://tuvidafm.com/">TuVidaFM.com</a> o sino por nuestro <Link to="/podcast">podcast</Link>.

                                </p>
                            </Grid.Column>
                            <Grid.Column floated='right' width={6}>
                                <Image bordered rounded size='large' src='/images/home.jpg' alt='Tocadiscos de cerca'/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Link to="/en-vivo" >
                                <Button size='huge'>Escúchanos</Button>
                                </Link>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
{/*
                <Segment style={{padding: '0em'}} vertical>
                    <Grid celled='internally' columns='equal' stackable>
                        <Grid.Row textAlign='center'>
                            <Grid.Column style={{paddingBottom: '5em', paddingTop: '5em'}}>
                                <Header as='h3' style={{fontSize: '2em'}}>
                                    "What a Company"
                                </Header>
                                <p style={{fontSize: '1.33em'}}>That is what they all say about us</p>
                            </Grid.Column>
                            <Grid.Column style={{paddingBottom: '5em', paddingTop: '5em'}}>
                                <Header as='h3' style={{fontSize: '2em'}}>
                                    "I shouldn't have gone with their competitor."
                                </Header>
                                <p style={{fontSize: '1.33em'}}>
                                    <Image avatar src='/images/avatar/large/nan.jpg'/>
                                    <b>Nan</b> Chief Fun Officer Acme Toys
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>*/}

                <Segment style={{padding: '8em 0em'}} vertical>
                    <Container text>
                        <Divider
                            as='h4'
                            className='header'
                            horizontal
                            style={{margin: '0em 0em', textTransform: 'uppercase'}}
                        >
                            <Link to="/club" >The Club</Link>
                        </Divider>

                        <Header as='h3' style={{fontSize: '2em'}}>
                            Vinos y Vinilos The Club
                        </Header>
                        <p style={{fontSize: '1.33em'}}>
                            No te olvides que también te ofrecemos formar parte del Vinos y Vinilos The Club y así ayudarte a hacer crecer tu colección de vinos y vinilos.
                        </p>
                        <Link to="/club" >
                            <Button as='a' size='large'>
                                Únete hoy
                            </Button>
                        </Link>
                        <Divider
                            as='h4'
                            className='header'
                            horizontal
                            style={{margin: '3em 0em', textTransform: 'uppercase'}}
                        >
                            <a target="_blank" rel="noopener noreferrer" className="item" href="https://www.facebook.com/groups/2022814671134081">
                                Facebook</a>
                        </Divider>
                        <Header as='h3' style={{fontSize: '2em'}}>
                            Grupo de Facebook
                        </Header>
                        <p style={{fontSize: '1.33em'}}>
                            No dejes de seguirnos en facebook y en nuestras otras redes sociales.
                        </p>
                        <a target="_blank" rel="noopener noreferrer" className="item" href="https://www.facebook.com/groups/2022814671134081">
                            <Button as='a' size='large'>
                                Ingresa al Grupo
                            </Button>
                        </a>



                    </Container>
                </Segment>
            </ResponsiveContainer>
        );
    }
}
export default HomePage;
