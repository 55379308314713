import React from "react";
import ResponsiveContainer from "../../components/layout/ResponnsiveContainer";
import {Grid, Header, Image, Segment} from "semantic-ui-react";

class LivePage extends React.Component {
    componentDidMount = async ()  => {
        window.scrollTo(0, 0);
    }
    render() {
        const tunein =
            <iframe src="https://tunein.com/embed/player/s253237"   width="100%" height="232" title="TuVidaFM.com en TuneIn"
                    frameBorder="no"></iframe>;
        return (
            <ResponsiveContainer>
                <Segment style={{padding: '8em 0em'}} vertical>
                    <Grid container stackable verticalAlign='top'>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Header as='h3' style={{fontSize: '2em'}}>
                                    En Vivo
                                </Header>
                                <p style={{fontSize: '1.33em'}}>
                                    Formamos parte de la parrilla de <a target="_blank" rel="noopener noreferrer" className="item" href="https://tuvidafm.com/">TuVidaFM.com</a>.
                                    Sintonízanos en vivo durante la semana en los siguientes horarios:
                                    <div className="ui bulleted list">
                                        <div className="item">Domingos a las 7pm</div>
                                        <div className="item">Martes a las 8pm</div>
                                        <div className="item">Jueves a las 8pm</div>
                                    </div>
                                    <div>*Todos los horarios son de la hora local de Washington, DC.
                                    </div>
                                </p>
                                {tunein}
                            </Grid.Column>
                            <Grid.Column floated='right' width={6}>
                                <Image bordered rounded size='large' src='/images/live.jpeg' alt='Logo de TuVidaFM.com'/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </ResponsiveContainer>
        );
    }
}
export default LivePage;
